@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,600;1,600&family=Teachers:ital,wght@0,400..800;1,400..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,800&display=swap');
/* @import url("https://use.typekit.net/ujw7xgd.css"); */

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
    html {
        font-family: "Poppins", sans-serif;
        /* font-family: "inter-variable", sans-serif; */
    }
    input, p, h1,h2,h3,h4, h5, label{
        /* font-family: Inter Variable,sans-serif; */
    }

    .poppins-extralight {
        font-family: "Poppins", sans-serif;
        font-weight: 200;
        font-style: normal;
    }

    .poppins-light {
        font-family: "Poppins", sans-serif;
        font-weight: 300;
        font-style: normal;
    }

    .poppins-regular {
        font-family: "Poppins", sans-serif;
        font-weight: 400;
        font-style: normal;
    }

    .poppins-medium {
        font-family: "Poppins", sans-serif;
        font-weight: 500;
        font-style: normal;
    }

    .poppins-semibold {
        font-family: "Poppins", sans-serif;
        font-weight: 600;
        font-style: normal;
    }

    .poppins-bold {
        font-family: "Poppins", sans-serif;
        font-weight: 700;
        font-style: normal;
    }

    .poppins-extrabold-italic {
        font-family: "Poppins", sans-serif;
        font-weight: 800;
        font-style: italic;
    }

    .poppins-extrabold {
        font-family: "Poppins", sans-serif;
        font-weight: 800;
        font-style: normal;
    }

    
      
    /* Custom scrollbar styles */

    /* ::-webkit-scrollbar-track {
        background: #f1f1f1;
    } */
    @media (min-width: 767px) { /* Adatta 767px alla tua breakpoint desiderata */

        *::-webkit-scrollbar,
        *::-webkit-scrollbar-thumb {
            width: 27px;
            border-radius: 13px;
            background-clip: padding-box;
            border: 10px solid transparent;
        }

        *::-webkit-scrollbar-thumb {        
            box-shadow: inset 0 0 0 10px #a5a5a5;
        }
        *::-webkit-scrollbar-thumb:hover{
            box-shadow: inset 0 0 0 10px #6a6a6a;

        }

        .scrollbar-h::-webkit-scrollbar,
        .scrollbar-h::-webkit-scrollbar-thumb {
            /* width: 2px; */
            width: 0px;
            border-radius: 13px;
            background-clip: padding-box;
            border: 0px solid transparent;

        }

        .scrollbar-h::-webkit-scrollbar-thumb {        
            box-shadow: inset 0 0 0 10px #a5a5a5;
        }
        .scrollbar-h::-webkit-scrollbar-thumb:hover{
            box-shadow: inset 0 0 0 10px #6a6a6a;

        }
    }
}

@layer components {
    
  
    .active{
        /* text-color: theme('colors.text-indigo-500'); */
    }
    .transform-ftp{
        transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
    }
    .filter-blur{
        --tw-backdrop-blur: blur(8px);
        -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
        backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
    }
    .uscbry33{
        transition-property: opacity;
        transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
        transition-duration: 150ms;
        -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
        backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
    }
    .mask-avatar {
        -webkit-mask-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMDAiIGhlaWdodD0iMjAwIj48cGF0aCBkPSJNMTAwIDBDMjAgMCAwIDIwIDAgMTAwczIwIDEwMCAxMDAgMTAwIDEwMC0yMCAxMDAtMTAwUzE4MCAwIDEwMCAwIi8+PC9zdmc+);
        mask-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMDAiIGhlaWdodD0iMjAwIj48cGF0aCBkPSJNMTAwIDBDMjAgMCAwIDIwIDAgMTAwczIwIDEwMCAxMDAgMTAwIDEwMC0yMCAxMDAtMTAwUzE4MCAwIDEwMCAwIi8+PC9zdmc+);
    }
    .mask {
        -webkit-mask-position: center;
        mask-position: center;
        -webkit-mask-repeat: no-repeat;
        mask-repeat: no-repeat;
        -webkit-mask-size: contain;
        mask-size: contain;
    }

    .acs > :not([hidden]) ~ :not([hidden]) {
        --tw-space-y-reverse: 0;
        margin-top: calc(1.5rem* calc(1 - var(--tw-space-y-reverse)));
        margin-bottom: calc(1.5rem* var(--tw-space-y-reverse));
    }

    
    
    .bg-primary {
        --tw-bg-opacity: 1;
        background-color: rgb(185 28 28 / var(--tw-bg-opacity));
    }
    .text-primary {
        --tw-bg-opacity: 1;
        color: rgb(185 28 28 / var(--tw-bg-opacity));
    }
    .fill-primary {
        --tw-bg-opacity: 1;
        color: rgb(185 28 28 / var(--tw-bg-opacity));
    }
    .border-primary {
        --tw-bg-opacity: 1;
        border-color: rgb(185 28 28 / var(--tw-bg-opacity));
    }

    
}
