.fade-enter {
    opacity: 0;
    position: absolute;
    width: 100%;
    height: 100%;
}

.fade-enter-active {
    opacity: 1;
    transition: opacity 200ms ease-in, transform 200ms ease-in;
    position: absolute;
    width: 100%;
    height: 100%;
}

.fade-exit {
    opacity: 1;
    position: absolute;
    width: 100%;
    height: 100%;
}

.fade-exit-active {
    opacity: 0;
    transition: opacity 200ms ease-in, transform 200ms ease-in;
    position: absolute;
    width: 100%;
    height: 100%;
}
.fade-enter-done{
    position: absolute;
    width: 100%;
    height: 100%;
}

.view-transition::view-transition {
    transition: opacity 0.5s ease-in-out;
}

.view-transition::view-transition-old {
    opacity: 0;
}

.view-transition::view-transition-new {
    opacity: 1;
}
  