
.rbc-time-slot{
    position: relative;
}
.rbc-time-slot .rbc-label{
    position: relative;
    left: 0px;
    top: -13px;
    background: #f1f5f9a5;
    border-radius: 5px;
}
.rbc-event, .rbc-day-slot .rbc-background-event{
    background-color: rgb(236 236 236);
    border-color: rgb(202 208 216) !important;
}
.rbc-event.rbc-selected{
    background-color: rgb(236 236 236);
    border-color: rgb(202 208 216) !important; 
}
.rbc-event:focus{
    outline: 5px auto rgb(185 28 28 / 1);
}

.rbc-calendar .ma-slots .rbc-timeslot-group .rbc-time-slot:before,
.rbc-calendar .rbc-day-slot .rbc-timeslot-group .rbc-time-slot:before{
    /* min-height: 40px; */
    content: "";
    width: 10px;
    border: 1px solid rgb(148 163 184 / 1);
    display: block;
    height: 100%;
    position: relative;
}

.rbc-calendar .ma-slots .rbc-timeslot-group, .rbc-calendar .rbc-timeslot-group{
    /* min-height: 160px; */
    min-height: 90px;
}
.rbc-calendar .rbc-timeslot-group{
    border-bottom: 1px dashed rgb(148 163 184 / .5);
    border-left: none;
    /* border-right: 1px solid rgb(226 232 240 / 2); */
}
.rbc-day-slot .rbc-time-slot{
    border: none;
}
.rbc-current-time-indicator{
    background: rgb(239 68 68);
}
.rbc-current-time-indicator:before{
    content: "";
    width: 5px;
    height: 5px;
    position: absolute;
    background: red;
    border-radius: 100%;
    top: -2.1px;
    left: -5px;
}

.rbc-events-container {
    margin: 10px;
}

.rbc-day-slot .rbc-event-label{
    display: none;
}
.rbc-allday-cell { display: none; } 
.rbc-time-content{
    padding-top: 10px;
    /* border: 1px solid rgb(226 232 240 / 1); */
    border-color: rgb(226 232 240);
}

.rbc-header + .rbc-header, .rbc-header{
    border: none;
    padding: 5px;
}
.rbc-time-header-content{
    border: none;
}
.rbc-time-header.rbc-overflowing{
    border: none;
}
.rbc-button-link{
    font-weight: 400;
}

.rbc-day-slot{
    /* width: 56rem !important;
    display: inline-block */
}
.rbc-time-view{
    border: none;
}

/* Aggiungi il bordo a tutte le righe delle ore */
.rbc-time-slot {
    /* border-bottom: 1px solid #ddd;  */
}
  
/* Aggiungi un bordo al calendario (tutto il contenitore delle righe delle ore) */
.rbc-time-column {
    border-right: 1px solid #ddd;
}
.dark .rbc-time-column, .dark .rbc-time-content, .dark .rbc-calendar .rbc-timeslot-group{
    border-right: 1px solid rgb(30 41 59);
    border-color:rgb(30 41 59);
}

.rbc-addons-dnd-resize-ns-icon{
    color:rgb(202 208 216);
}

.dark .rbc-today{
    background:  rgb(20 31 57)
}
.dark .rbc-event.rbc-selected {
    background-color: rgb(13 20 35);
    border-color: rgb(10 15 27) !important;
}
.dark .rbc-event, .dark .rbc-day-slot .rbc-background-event{
    background-color: rgb(13 20 35);
    border-color: rgb(10 15 27) !important;
}

.rbc-addons-dnd .rbc-addons-dnd-resize-ns-anchor{
    height:10px;
    width: 100%;
    cursor: ns-resize;
    display: flex;
    justify-content: center;
    align-items: center;
}
.rbc-addons-dnd .rbc-addons-dnd-resize-ns-anchor:hover{
    background: rgba(202 208 216 / 0.4);
    border-radius: 10px;
}